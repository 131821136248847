<template>
  <c-box flexGrow="1">
    <c-breadcrumb
      fontSize="14px"
      lineHeight="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link
          color="brand.900"
          as="router-link"
          to="/management/diet-kuesioner"
        >
          Manajemen Diet Kuesioner
        </c-breadcrumb-link>
      </c-breadcrumb-item>
      <c-breadcrumb-item>
        <c-breadcrumb-link isCurrent> Detail Diet Kuesioner </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />
    <c-flex justify="space-between" align="center" mb="30px">
      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
      >
        Detail Diet Kuesioner
      </c-heading>
      <c-box>
        <c-button
          variant="solid"
          variant-color="red"
          border-radius="60px"
          min-w="114px"
          h="auto"
          py="13px"
          px="18px"
          mr="20px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          @click="isOpen = true"
        >
          <c-image
            :src="require('@/assets/icon-trash.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Hapus
        </c-button>
        <c-button
          variant="solid"
          variant-color="brand"
          border-radius="60px"
          h="auto"
          min-w="114px"
          py="13px"
          px="18px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          as="router-link"
          :to="{
            name: 'superadmin.diet-kuesioner.edit',
            params: { id: id },
          }"
        >
          <c-image
            :src="require('@/assets/icon-pencil.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Edit
        </c-button>
      </c-box>
    </c-flex>

    <c-box v-if="item">
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          ID Question
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-weight="500"
          font-size="18px"
          line-height="27px"
        >
          {{ item.id }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Kategori Question
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          textTransform="capitalize"
        >
          {{ item.category ? item.category.replace("_", " ") : "" }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Question
        </c-text>
        <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
          {{ item.question }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Subtext (Instruksi Kepada Klien)
        </c-text>
        <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
          {{ item.subtext }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Jenis Jawaban
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          textTransform="capitalize"
        >
          {{ item.answerType ? item.answerType.replace("_", " ") : "" }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Opsi Jawaban
        </c-text>
        <c-list v-if="item.answerContent" styleType="disc">
          <c-list-item
            v-for="obj in item.answerContent"
            :key="obj.id"
            ml="12px"
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
          >
            {{ obj.answer }}
          </c-list-item>
        </c-list>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="13px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Required or Not
        </c-text>
        <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
          <c-badge
            variant="outline"
            :variant-color="item.isRequired ? 'red' : 'blue'"
            :bg="
              item.isRequired
                ? 'rgba(234, 67, 53, 0.1)'
                : 'rgba(0, 117, 225, 0.1)'
            "
            font-size="10px"
            line-height="15px"
            border-radius="35px"
            py="2px"
            px="10px"
          >
            {{ item.isRequired ? "REQUIRED" : "NOT REQUIRED" }}
          </c-badge>
        </c-text>
      </c-form-control>
    </c-box>

    <DeleteConfirm
      zIndex="1000"
      @on-delete="onDelete"
      :content="deletedText"
      :isOpen="isOpen"
    />
  </c-box>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DeleteConfirm from "./../delete-confirm.vue";

export default {
  name: "SuperadminDietKuesionerDetail",
  components: { DeleteConfirm },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState({
      item: (s) => s.suKuisioner.item,
    }),
    id() {
      return this.$route.params.id;
    },
    deletedText() {
      return `Anda yakin ingin hapus Diet Kuesioner <br /> “${this.item?.id}”?`;
    },
  },
  methods: {
    ...mapActions({
      detailKuesioner: "suKuisioner/detailKuesionerSuperadmin",
      deleteKuesioner: "suKuisioner/deleteKuesionerSuperadmin",
    }),
    closeDialog() {
      this.isOpen = false;
    },
    onDelete(state) {
      this.isOpen = state;
      if (!this.isOpen) return;
      this.deleteKuesioner(this.id)
        .then(() => {
          this.$toast({
            title: "Success",
            description: "Diet Kuesioner has been deleted.",
            status: "success",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
          this.$router.push({
            name: "superadmin.diet-kuesioner",
          });
        })
        .catch((err) => {
          this.$toast({
            title: "Failed",
            description: err.data.message ?? "Ops! Something when wrong.",
            status: "error",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
        });
    },
  },
  async mounted() {
    this.detailKuesioner(this.id);
  },
};
</script>
